import { CountryCodeList } from 'types/countries';
import { ThemeColors, ThemeConfig } from './themeConfig';
import { translate } from '../utils/translations';
import { SiteLanguage } from '../constants/locale';
import { EXP_CANADA_ADDRESS } from './exprealtyCA';
import keywordsCA from './keywordsCA';
import { Colors } from 'constants/colors';

export const ZOOCASA_ADDRESS = '52 Church St Suite\n464 Toronto, ON M5C 2B5';

const themeColors: ThemeColors = {
  primaryVeryLight: Colors.sandVeryLight,
  primaryLight: '#F8F5ED',
  primary: '#B38E57',
  primaryDark: Colors.black,
  primaryVeryDark: '#886A3F',
  primaryBackground: '#181B1E',
  primaryFontColor: '#FFF',
  primaryForSale: '#4695C4',
  primarySold: '#DF6541',
  primarySoldFocus: '#191B1E',
  primaryFilters: '#4695c4',
  primaryLinkColor: '#4695c4',
  primaryFocusLinkColor: '#31739B',
  primaryInvertedBackground: '#191B1E',
  primaryBorderColor: '#B38E57',
  primaryFocusColor: '#47494B',
  primaryFocusBorderColor: '#47494B',
  primaryChart: '#F6F4F4',
  secondaryBackground: '#EFEFEF',
  secondaryBorderColor: '#181B1E',
  secondaryLinkColor: '#46484A',
  secondaryHoverColor: '#191B1E',
  secondaryFocusColor: '#47494B',
  secondaryFocusBorderColor: '#47494B',
  tertiaryBackground: '#FFF',
  tertiaryBackgroundLight: Colors.warmBlack5,
  tertiaryBorderColor: Colors.black,
  tertiaryLinkColor: '#31587F',
  quaternaryLinkColor: '#31587F',
  quaternaryBorderColor: '#191B1E',
  quaternaryBackgroundLight: Colors.warmBlack5,
  transparentBackground: 'rgba(12, 15, 36, 0.5)',
  mapPinForSale: '#4695c4',
  mapPinForSaleFocus: '#191B1E',
  mapControlBackground: 'rgba(25, 27, 30, .8)',
  mapControlBackgroundHover: 'rgba(25, 27, 30, .8)',
  mapControlBackgroundActive: 'rgba(25, 27, 30, .93)',
  carouselArrowHover: Colors.warmBlack5,
  carouselArrowFocus: Colors.warmBlack10,
  notFound404PinHeadColor: '#D35313',
  notFOund404PinBodyColor: '#F26721',
};

const ZoocasaTheme: ThemeConfig = {
  name: 'Zoocasa',
  title: 'Zoocasa Real Estate Listings - Houses, Condos, Property for Sale',
  metaDescription: 'Find real estate listings for sale in Canada. Search MLS real estate listings for homes, condos, and properties on Zoocasa.com, Top real estate website in Canada.',
  largeDesktopHeaderImage: undefined,
  desktopHeaderImage: undefined,
  mobileHeaderImage: undefined,
  smallMobileImage: undefined,
  ogSiteName: 'Zoocasa.com',
  ogImage: 'https://www.zoocasa.com/next/assets/images/banner.png',
  schemaUrl: 'https://www.zoocasa.com',
  schemaLogo: 'https://www.zoocasa.com/assets/images/company/press/zoocasa-logo.jpg',
  schemaLocation: {
    addressCountry: 'Canada',
    addressLocality: 'Toronto',
    addressRegion: 'ON',
    postalCode: 'M5A4J5',
    streetAddress: '250 The Esplanade Suite 408',
  },
  schemaFoundingDate: '2015',
  schemaFounders: [
    {
      '@type': 'Person',
      name: 'Lauren Haw',
    },
  ],
  faviconPath: 'favicon-zoocasa.ico',
  colors: themeColors,
  fonts: {
    heading: 'Frank Ruhl Libre',
    text: 'Mulish',
    headingWeight: 'initial',
    headingBoldWeight: '600',
  },
  decorations: {
    brushstrokes: true,
    boxShadow: 'unset',
    boxShadowLight: '0 0 8px 0 rgba(0, 0, 0, .1)',
    areaPageLineWidth: '111px',
  },
  buttons: {
    borderRadius: '8px',
    borderRadiusSmall: '0px',
    borderRadiusMedium: '8px',
    borderRadiusLarge: '8px',
    borderRadiusModal: '0px',
    borderRadiusBanner: '8px',
  },
  icons: {
    borderRadius: '8px',
  },
  style_overrides: {
    areaListingsPage: {
      pageTitleFontWeight: 'normal',
    },
    icon: {
      square: {
        hoverBackground: {
          light: Colors.warmBlack5,
          dark: Colors.greyLight,
        },
        focusBackground: {
          light: Colors.black10,
          dark: Colors.greyLight2,
        },
      },
    },
  },
  organization: {
    phoneNumber(isOnPreConPage) {
      return isOnPreConPage ? '1-647-952-6657' : '1-844-683-4663';
    },
    email(isOnPreConPage) {
      return isOnPreConPage ? 'newhomes@zoocasa.com' : 'INFO@ZOOCASA.COM';
    },
    address(siteLocation) {
      return siteLocation === CountryCodeList.UNITED_STATES ? EXP_CANADA_ADDRESS : ZOOCASA_ADDRESS;
    },
    twitterUrl: 'https://x.com/zoocasa',
    facebookUrl: 'https://www.facebook.com/Zoocasa/',
    instagramUrl: 'https://www.instagram.com/zoocasa/',
    linkedinUrl: 'https://www.linkedin.com/company/zoocasa-com/',
  },
  menuLinks: [
    {
      id: 'home-appraisal',
      label: 'Home Appraisal',
      link: '/home-appraisal',
      isEmber: true,
    },
    {
      id: 'market-insights',
      label: 'Market Insights',
      link: 'https://www.zoocasa.com/blog/',
      isEmber: true,
    },
    {
      id: 'newsletter',
      label: 'Newsletter',
      link: 'https://zoocasa.ac-page.com/newsletter',
    },
    {
      id: 'new-construction',
      label: 'New Construction',
      link: '/newhomes',
    },
    {
      id: 'careers',
      label: 'Careers',
      link: '',
      nested: [
        {
          id: 'headquarters',
          label: 'Headquarters',
          link: 'https://jobs.jobvite.com/exprealty/jobs',
        },
        {
          id: 'agent-partners',
          label: 'Agent Partners',
          link: 'https://careers.zoocasa.com/partnernetwork/',
        },
        {
          id: 'agent-accelerators',
          label: 'Agent Accelerators',
          link: 'https://careers.zoocasa.com/agent-accelerator/',
        },
      ],
    },
    {
      id: 'about-us',
      label: 'About Us',
      link: '/about-us',
      isEmber: true,
    },
    {
      id: 'accessibility',
      label: 'Accessibility',
    },
    {
      id: 'sitemap',
      label: 'Sitemap',
      link: '/sitemap',
    },
    {
      id: 'chat',
      label: 'Chat',
    },
  ],
  footerContent: {
    joinUsLink: '/search',
    footerImageData: {
      src: '/next/assets/images/zoocasa-footer-pic.png',
      alt: 'A group of people at an event are laughing and cheering, with one man enthusiastically greeting a seated couple in the front row.',
    },
  },
  searchOptions: [
    {
      type: 'Buy',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Rent',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Sold',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'HomeAppraisal',
      placeholder() {
        return 'Enter your home address';
      },
    },
  ],
  contentBlockBuy: {
    title: 'Looking to Buy a Home?',
    copy: 'We deliver the best client experience and ensure home buyers have the tools they need to secure a property in today’s competitive market conditions. Connect with one of our team members today to learn more. ',
    image: '/next/assets/images/content-block/zoocasa-buy.jpg',
    link: '/buy-with-zoocasa',
  },
  contentBlockSell: {
    title: 'Thinking about Selling?',
    copy: 'We ensure every homeowner receives the most personalized and tailored service from our team.  Service that is as unique to them as their property. Connect with us now to get an in-home evaluation.',
    image: '/next/assets/images/content-block/zoocasa-sell.jpg',
    link: '/sell-with-zoocasa',
  },
  privacyPolicy: (language?: SiteLanguage) => {
    switch (language) {
    default:
      return 'https://www.zoocasa.com/privacy-policy';
    }
  },
  termsOfUse: (language?: SiteLanguage) => {
    switch (language) {
    default:
      return 'https://www.zoocasa.com/terms-of-use';
    }
  },
  keywords: keywordsCA,
};

export default ZoocasaTheme;
