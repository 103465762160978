export function capitalize(str: string) {
  if (!str) return '';

  // Find the first alphabetic character (including Unicode)
  const firstAlphaIndex = str.search(/[\p{L}]/u);
  if (firstAlphaIndex === -1) return str;

  // Split the string into parts before, at, and after the first alpha
  const beforeAlpha = str.slice(0, firstAlphaIndex);
  const firstAlpha = str[firstAlphaIndex].toUpperCase();
  const afterAlpha = str.slice(firstAlphaIndex + 1).toLowerCase();

  return beforeAlpha + firstAlpha + afterAlpha;
}

export function capitalizeWords(str: string, trim = false): string {
  if (!str) return '';

  const lowerCaseExceptions = new Set(['in', 'on', 'is', 'to', 'width', 'and', 'by', 'a', 'at', 'the', 'of', 'la', 'for']);
  const uppercaseExceptions = new Set(['usa']);
  // Apply conditional trimming based on the trim parameter
  const processedStr = trim ? str.replace(/\s+/g, ' ').trim() : str.replace(/\s+/g, ' ');
  const words = processedStr.split(' ');

  // If it's a single word that is not an exception, capitalize it
  if (words.length === 1 && lowerCaseExceptions.has(words[0])) {
    return words[0];
  }

  return words.map((originalWord, index) => {
    let word = originalWord.trim();

    // Handle email addresses
    if (word.includes('@')) {
      const [localPart, domain] = word.split('@');
      const [domainName, ...tldParts] = domain.split('.');
      return capitalize(localPart) + '@' +
             capitalize(domainName) +
             (tldParts.length ? '.' + tldParts.join('.') : '');
    }

    // Handles hyphenated words
    if (word.includes('-')) {
      return word.split('-')
        .map(part => capitalize(part.toLowerCase()))
        .join('-');
    }

    const lowerWord = word.toLowerCase();
    // Check if the word contains an uppercase exception that is either:
    // 1. At the start of the word
    // 2. Preceded by a non-letter character
    // 3. Followed by a non-letter character or end of word
    for (const exception of uppercaseExceptions) {
      const exceptionIndex = lowerWord.indexOf(exception);
      if (exceptionIndex !== -1) {
        const isAtStart = exceptionIndex === 0;
        const isPrecededByNonLetter = exceptionIndex > 0 && !/[\p{L}]/u.test(lowerWord[exceptionIndex - 1]);
        const isFollowedByNonLetter = exceptionIndex + exception.length < lowerWord.length &&
                                    !/[\p{L}]/u.test(lowerWord[exceptionIndex + exception.length]);
        const isAtEnd = exceptionIndex + exception.length === lowerWord.length;

        if (isAtStart || isPrecededByNonLetter) {
          if (isAtEnd || isFollowedByNonLetter) {
            return lowerWord.slice(0, exceptionIndex) +
                   lowerWord.slice(exceptionIndex, exceptionIndex + exception.length).toUpperCase() +
                   lowerWord.slice(exceptionIndex + exception.length);
          }
        }
      }
    }

    // Always capitalize the first word of the sentence
    if (index === 0) {
      return capitalize(lowerWord);
    }
    if (lowerCaseExceptions.has(lowerWord)) {
      return lowerWord;
    }
    return capitalize(lowerWord);
  }).join(' ');
}


export function capitalizeSentences(str: string) {
  return splitSentencesStrict(str).map(capitalize).join('');
}

function splitSentencesStrict(text: string): string[] {
  const result = [];
  let current = '';
  let i = 0;

  while (i < text.length) {
    const next3 = text.slice(i, i + 3);

    // Handle ellipsis
    if (next3 === '...') {
      current += '...';
      i += 3;
      continue;
    }

    const char = text[i];
    current += char;

    // Split if this is a sentence terminator (.!?), but NOT part of an ellipsis
    if ((char === '.' || char === '!' || char === '?')) {
      // Peek ahead: if not part of ellipsis (we already handled above), split here
      result.push(current);
      current = '';
    }

    i++;
  }

  // Add any remaining text
  if (current) {
    result.push(current);
  }

  return result;
}